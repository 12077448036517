/* Hero Component Styles */

/* Container Styling */
.hero-section {
  margin-top: 80px;
  padding-top: 2rem;
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.hero-content {
  flex: 1;
}

.hero-content h1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.greeting {
  font-size: 1.5rem;
  color: var(--color-text);
}

.name {
  font-size: 3.5rem;
  color: var(--color-primary);
  font-weight: 700;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, rgb(200, 25, 100), rgb(200, 25, 100));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: shimmer 6s infinite linear;
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.description {
  color: var(--color-text);
  max-width: 600px;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-image::before {
  content: '';
  position: absolute;
  width: 277px;  /* Reduced from 308px (10% smaller) */
  height: 277px;  /* Reduced from 308px (10% smaller) */
  border-radius: 50%;
  background: linear-gradient(45deg, rgb(200, 25, 100), rgb(200, 25, 100));
  filter: blur(20px);
  opacity: 0.5;
  animation: rotate 6s linear infinite;
}

.hero-image img {
  width: 270px;  /* Reduced from 300px (10% smaller) */
  height: 270px;  /* Reduced from 300px (10% smaller) */
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  z-index: 1;
  background: var(--color-background);
}

@keyframes rotate {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
  
  .hero-content h1 {
    align-items: center;
  }
  
  .description {
    margin: 0 auto;
  }
}







/* Background Container */
.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensure background is behind all content */
    overflow: hidden;
}

/* Static Background */
.background {
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, rgb(17, 24, 33), rgb(18, 18, 18));
    position: absolute;
    top: 0;
    left: 0;
}

/* Flying Pixels */
.pixel {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 0 8px 2px rgba(255, 87, 34, 0.6);
    animation: fly linear infinite;
}

/* Animation */
@keyframes fly {
    from {
        transform: translateY(100vh) translateX(0) scale(1);
        opacity: 1;
    }
    to {
        transform: translateY(-100vh) translateX(50vw) scale(0.5);
        opacity: 0;
    }
}

/* Ensure Main Content is Above the Background */
.app-content {
    position: relative;
    z-index: 1;
}
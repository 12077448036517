.skills-section {
  text-align: center;
  padding-top: 0;
}

.skills-section h2 {
  margin-bottom: 3rem;
  font-size: 2.5rem;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto 4rem;
  justify-content: center;
}

.skills-category {
  flex: 0 1 calc(33.333% - 2rem);
  min-width: 280px;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  transition: all 0.3s ease;
}

/* Remove the hover effect for mobile */
@media (min-width: 769px) {
  .skills-category:hover {
    transform: translateY(-10px);
    border-color: var(--color-hover);
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  }
}

.skills-category h3 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.skill-item {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.skill-item:hover {
  transform: scale(1.1);
}

.additional-skills {
  max-width: 1000px;
  margin: 0 auto;
}

.additional-skills h3 {
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.skill-tag {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.skill-tag:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .skills-category {
    padding: 1.5rem;
  }

  /* Add the in-view animation for mobile */
  .skills-category.in-view {
    transform: translateY(-10px);
    border-color: var(--color-hover);
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
    animation: subtle-pulse 2s infinite;
  }

  .additional-skills {
    margin-top: 2rem;
  }

  .skills-list {
    gap: 0.8rem;
  }

  .skill-item {
    padding: 0.4rem 0.8rem;
  }
} 
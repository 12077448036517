.contact-section {
  text-align: center;
  padding-top: 0;
}

.contact-section h2 {
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.section-description {
  max-width: 600px;
  margin: 0 auto 2rem;
  color: var(--color-text);
  font-size: 1.1rem;
}

.separator {
  width: 100%;
  max-width: 400px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 auto 2rem;
}

.contact-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto;
}

.contact-link {
  color: var(--color-text);
  text-decoration: none;
  padding: 0.8rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.contact-link:hover {
  color: var(--color-primary);
  transform: translateY(-2px);
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 768px) {
  .contact-links {
    padding: 0 1.5rem;
  }
}
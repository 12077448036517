.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  backdrop-filter: blur(10px);
  padding: 1rem 0;
  z-index: 1000;
  background: rgba(18, 18, 18, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
}

.navbar-logo a {
  color: #faf0e6;
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: none;
  font-family: var(--font-heading);
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.navbar-menu li a {
  color: #faf0e6;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.navbar-menu li a:hover {
  color: #61dafb;
}

/* Hamburger menu icon */
.hamburger {
  display: none;
  cursor: pointer;
  padding: 10px;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #faf0e6;
  margin: 5px 0;
  transition: all 0.3s ease;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hamburger {
    display: block;
    z-index: 1001;
    margin-right: 0.5rem;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: rgba(18, 18, 18, 0.95);
    backdrop-filter: blur(15px);
    padding-top: 80px;
    transform: translateX(100%);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .navbar-menu.active {
    display: flex;
    transform: translateX(0);
    opacity: 1;
  }

  .navbar-menu li {
    margin: 1rem 0;
    text-align: right;
    padding-right: 2rem;
  }

  .navbar-menu li a {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    display: inline-block;
    position: relative;
    color: var(--color-text);
  }

  .navbar-menu li a::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 130%;
    background: rgba(200, 25, 100, 0.1);
    filter: blur(15px);
    border-radius: 90%;
    z-index: -1;
  }

  .navbar-menu li a:hover {
    color: #61dafb; /* Light blue on hover */
  }

  .hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }

  .navbar-container {
    padding: 0 1rem;
  }
} 
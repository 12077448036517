.about-section {
  text-align: center;
  padding-top: 0;
}

.about-section h2 {
  margin-bottom: 3rem;
  font-size: 2.5rem;
}

.timeline {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.timeline-item {
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.timeline-item:hover {
  transform: translateY(-10px);
  border-color: var(--color-hover);
  /* Add glow effect */
  box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
}

.timeline-item h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .timeline {
    grid-template-columns: 1fr;
  }
  .timeline-item.in-view {
    transform: translateY(-10px);
    border-color: var(--color-hover);
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
    animation: subtle-pulse 2s infinite;
  }
}


/* =========================
   Theme Variables
========================= */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700&display=swap');

:root {
    --color-background: #121212;
    --color-primary: rgb(31, 111, 235);
    --color-secondary: rgb(200, 25, 100);
    --color-text: rgb(225, 225, 225);
    --color-accent: #03dac6;
    --color-border: #333333;
    --color-hover: rgba(31, 111, 235, 0.2);
    
    /* Font families */
    --font-heading: 'Poppins', sans-serif;
    --font-body: 'Poppins', sans-serif;
    --title-glow: rgba(31, 111, 235, 0.15);
    --title-gradient: linear-gradient(45deg, rgb(200, 25, 100), rgb(200, 25, 100));
    --subtitle-gradient: linear-gradient(45deg, rgb(200, 25, 100), rgb(200, 25, 100));
}

/* =========================
   Global Styles
========================= */
body {
    margin: 0;
    padding: 0;
    font-family: var(--font-body);
    background: radial-gradient(circle at center, #2a2a42, #121212);
    color: var(--color-text);
    line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-heading);
    font-weight: 600;
    line-height: 1.2;
}

/* Common styles */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;
}

section {
    scroll-margin-top: 80px;
    padding: 40px 1rem;
    min-height: 10vh;
    width: 100%;
    box-sizing: border-box;
}

.main-content {
    position: relative;
    z-index: 1;
}

h2 {
    padding-top: 1rem;
    position: relative;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    background: var(--title-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

h2::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140%;
    height: 130%;
    background: var(--title-glow);
    filter: blur(15px);
    border-radius: 90%;
    z-index: -1;
}

h3 {
    background: var(--subtitle-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}
.intro-text {
    font-size: 1.2rem;  /* Adjust between paragraph and h3 size */
    color: var(--color-text);
    margin-bottom: 0rem;
    font-weight: 300;
}
.subtitle-text {
    font-size: 2.5rem;  /* Adjust between paragraph and h3 size */
    color: var(--color-text);
    margin-bottom: 3rem;
    font-weight: 300;
}

/* Add after your existing styles */
@media (max-width: 768px) {
  .project-item.in-view {
    transform: translateY(-10px);
    border-color: var(--color-hover);
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  }
}

@media (max-width: 768px) {
  .item-in-view {
    transform: translateY(-10px);
    border-color: var(--color-hover);
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
    animation: subtle-pulse 2s infinite;
  }
}

@keyframes subtle-pulse {
  0% {
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  }
  70% {
    box-shadow: 0 0 30px rgba(200, 25, 100, 0.7);
  }
  100% {
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  }
}
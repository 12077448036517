.projects-section {
  text-align: center;
  padding-top: 0;
}

.projects-section h2 {
  margin-bottom: 3rem;
  font-size: 2.5rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.project-item {
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.project-item::before {
  content: 'Click to View Details';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 1rem;
  font-weight: 500;
  color: var(--color-text);
}

.project-item:hover::before {
  opacity: 1;
}

.project-item:hover {
  transform: translateY(-10px);
  border-color: var(--color-hover);
  box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  animation: subtle-pulse 2s infinite;
}

.project-image {
  margin-bottom: 1.5rem;
}

.project-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.project-item h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.project-item p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
  .timeline-item.in-view {
    transform: translateY(-10px);
    border-color: var(--color-hover);
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
    animation: subtle-pulse 2s infinite;
  }
} 

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  overflow-y: auto;
  padding: 20px;
  scroll-padding-top: 90px;
}

.modal-content {
  background-color: var(--color-background);
  border-radius: 1rem;
  padding: 2rem;
  max-width: 800px;
  width: 90%;
  margin: 90px auto 20px;
  position: relative;
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 0.3s ease forwards, modalGlow 3s ease-in-out infinite;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 30px rgba(200, 25, 100, 0.3);
  transition: box-shadow 0.3s ease;
  overflow-y: visible;
}

.modal-content:hover {
  box-shadow: 0 0 40px rgba(200, 25, 100, 0.5);
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(100, 100, 100, 0.1);
  border: 3px solid rgba(255, 255, 255, 0.7);
  color: var(--color-secondary);
  font-size: 1.6rem;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
  box-shadow: 0 0 15px rgba(200, 25, 100, 0.3);
  backdrop-filter: blur(2px);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding: 0;
}

.modal-close:hover {
  transform: rotate(90deg);
}

.modal-image {
  margin: -2rem -2rem 2rem -2rem;
}

.modal-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 1rem 1rem 0 0;
}

.modal-description {
  margin: 1.5rem 0;
  line-height: 1.6;
}

.modal-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1.5rem 0;
}

.tech-tag {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.9rem;
}

.modal-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  justify-content: center;
}

.modal-link {
  color: var(--color-text);
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.modal-link:hover {
  color: var(--color-primary);
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(200, 25, 100, 0);
  }
  to {
    transform: translateY(0);
    opacity: 1;
    box-shadow: 0 0 30px rgba(200, 25, 100, 0.3);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-overlay {
    padding: 10px;
    padding-top: 100px;
  }

  .modal-content {
    width: 95%;
    padding: 1rem;
    margin: 0 auto 20px;
  }

  .modal-image {
    margin: -1rem -1rem 1rem -1rem;
  }
  
  .modal-image img {
    height: 180px;
  }

  .modal-description {
    font-size: 0.9rem;
    margin: 1rem 0;
  }

  .tech-tag {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }

  .modal-technologies {
    gap: 0.4rem;
  }

  .modal-close {
    top: 0.5rem;
    right: 0.5rem;
    width: 35px;
    height: 35px;
    font-size: 1.6rem;
    backdrop-filter: blur(2px);
  }

  .modal-content h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

/* For very small screens */
@media (max-width: 380px) {
  .modal-content {
    margin-top: 80px;
    max-height: calc(100vh - 100px);
  }

  .modal-image img {
    height: 140px;
  }

  .tech-tag {
    font-size: 0.75rem;
    padding: 0.3rem 0.6rem;
  }
}

/* Add subtle pulse animation to indicate interactivity */
@keyframes subtle-pulse {
  0% {
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  }
  70% {
    box-shadow: 0 0 30px rgba(200, 25, 100, 0.7);
  }
  100% {
    box-shadow: 0 0 20px rgba(200, 25, 100, 0.5);
  }
}

.project-item:hover {
  animation: subtle-pulse 2s infinite;
}

/* Add a pulse animation for the modal */
@keyframes modalGlow {
  0% {
    box-shadow: 0 0 30px rgba(200, 25, 100, 0.3);
  }
  50% {
    box-shadow: 0 0 40px rgba(200, 25, 100, 0.5);
  }
  100% {
    box-shadow: 0 0 30px rgba(200, 25, 100, 0.3);
  }
}

.modal-content {
  animation: slideUp 0.3s ease forwards, modalGlow 3s ease-in-out infinite;
}

/* Add styles to handle body scroll lock */
body.modal-open {
  overflow: hidden;
  padding-right: 15px;
}
  